import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { images_ftp, placeholders } from "../../src/config/config";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90vh",
    maxWidth: "90vw",
  },
};

const paypalOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "capture",
};

Modal.setAppElement("#root");

export default function Results() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const resultId = searchParams.get("id") || null;

  const [isBlurred, setIsBlurred] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resdata, setData] = useState(null);
  const [details, setDetails] = useState(placeholders);
  const [modalOpen, setModalOpen] = useState(false);

  // Const color depending on data.rating, four steps from red (0) to yellow (10) to green (20) :
  const color = (rating) => {
    if (rating < 45) return "#FF6861";
    if (rating < 70) return "#FFB54C";
    return "#58a05b";
  };

  const getData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Get document from firestore
      const db = getFirestore();
      const docRef = doc(db, "results", resultId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const result = docSnap.data();
        setData(result);

        // Add first element of results.positives to placeholder
        setDetails({
          ...details,
          positives: [result.positives[0], ...details.positives],
        });

        if (result.unlocked) {
          setDetails(result);
          setIsBlurred(false);
        }
      } else {
        setError(t("Page not found"));
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onCreateOrder = (data, actions) => {
    setError(null);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "0.99",
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  const validatePayment = async (data, actions) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        process.env.REACT_APP_BACK_END + "/validate_payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileid: resultId,
            paypal_order_id: "test",
          }),
        }
      );
      if (response.ok) {
        actions.order.capture().then((details) => {
          setModalOpen(false);
          setDetails(resdata);
          setIsBlurred(false);
          setLoading(false);
        });
      } else {
        throw new Error(t("Error during checkout"));
      }
    } catch (error) {
      setError(t("Error during checkout"));
    } finally {
      setLoading(false);
    }
  };

  const displayData = () => {
    if (resdata?.unlocked) {
      setDetails(resdata);
      setIsBlurred(false);
    } else {
      setModalOpen(true);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="result">
      <Modal
        isOpen={modalOpen}
        contentLabel="Example Modal"
        style={modalStyles}
      >
        <a>
          <CloseIcon
            onClick={(e) => setModalOpen(false)}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
          />
        </a>
        {error && (
          <div style={{ textAlign: "center" }}>
            <p className="error">{error}</p>
          </div>
        )}
        <div className="checkoutbody">
          <div className="checkoutcol dashed">
            <h2>{t("Unlock full analysis")}</h2>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "40px",
                textAlign: "center",
              }}
            >
              0.99€
            </p>
            <p style={{ color: "#626262" }}>
              {t("Unlock long text1")}
              <br />
              {t("Unlock long text2")}
            </p>
          </div>
          <div className="checkoutcol" style={{ alignContent: "center" }}>
            <div style={{ maxWidth: "300px", margin: "auto" }}>
              <PayPalScriptProvider options={paypalOptions}>
                <PayPalButtons
                  style={{ layout: "vertical", color: "blue", shape: "pill" }}
                  onApprove={(data, actions) => validatePayment(data, actions)}
                  createOrder={(data, actions) => onCreateOrder(data, actions)}
                />
              </PayPalScriptProvider>
            </div>
          </div>
        </div>
      </Modal>
      {error && <p className="error">{error}</p>}
      {loading && (
        <div style={{ margin: "20px" }}>
          <CircularProgress />
        </div>
      )}
      {resdata && details && (
        <div>
          <div className="resulttop">
            <div>
              <a href={images_ftp + "/" + resultId + ".jpg"} target="_blank">
                <img
                  src={images_ftp + "/" + resultId + ".jpg"}
                  height={250}
                  class="resultimg"
                ></img>
              </a>
            </div>
            <div className="resulttoptext">
              <p
                style={{
                  marginBlockStart: "0",
                }}
              >
                {t("For the position of :")}
              </p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBlockStart: "0",
                }}
              >
                {resdata.poste}
              </p>
              <p
                style={{
                  marginBlockEnd: "0",
                }}
              >
                {t("Your rate :")}
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Gauge
                  width={150}
                  height={150}
                  value={resdata.rating}
                  // startAngle={-90}
                  // endAngle={90}
                  valueMin={0}
                  valueMax={100}
                  cornerRadius="50%"
                  sx={(theme) => ({
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 25,
                      fontWeight: 500,
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: color(resdata.rating),
                    },
                  })}
                  text={({ value, valueMax }) => `${value}%`}
                  // Params : https://mui.com/x/api/charts/gauge/
                />
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            {isBlurred && (
              <div>
                {modalOpen ? (
                  <div></div>
                ) : (
                  <div className="unlockbutton">
                    <Button variant="contained" onClick={displayData}>
                      {t("Unlock full analysis")}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <div className="resultbody">
              <div className="resultbody2 pos">
                <h3>{t("Positives :")}</h3>
                {details.positives.map((element, index) => (
                  <li class={index === 0 ? "" : isBlurred && "blur"}>
                    {element}
                  </li>
                ))}
              </div>
              <div style={{ width: "20px", height: "20px" }}></div>
              <div className="resultbody2 neg">
                <h3>{t("Negatives :")}</h3>
                {details.negatives.map((element) => (
                  <li class={isBlurred && "blur"}>{element}</li>
                ))}
              </div>
            </div>
          </div>
          <div className="backbutton">
            <Button
              variant="outlined"
              style={{ fontSize: "12px", backgroundColor: "#ffffff" }}
              component={Link}
              to="/"
            >
              ← {t("analyser un autre CV")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
