import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <p>
        &copy; 2024 JUVENTIN. {t("All rights reserved.")} -{" "}
        <a
          href="mailto:contact@cvchecker-ai.com"
          style={{ color: "white", textDecoration: "none" }}
        >
          Contact
        </a>{" "}
        -{" "}
        <a href="/legal" style={{ color: "white", textDecoration: "none" }}>
          {t("Legal")}
        </a>
      </p>
    </footer>
  );
}

export default Footer;
