import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { useTranslation } from "react-i18next";

import { FileUploader } from "../components/DragDropFiles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

function App() {
  const { t, i18n } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPoste, setSelectedPoste] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (f) => setSelectedFile(f);
  const onTextChange = (e) => setSelectedPoste(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setError("Veuillez sélectionner un fichier.");
      return;
    }

    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append("cv", selectedFile);
    formData.append("poste", selectedPoste);
    formData.append("lang", i18n.language.split("-")[0]);

    try {
      const response = await fetch(
        process.env.REACT_APP_BACK_END + "/analyze",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error(t("Erreur lors de l'analyse du CV."));
      }
      const result = await response.json();
      window.location.href = "/results?id=" + result.id;
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="body">
      <h1>{t("title")}</h1>
      <p>
        <b>CV Checker</b> {t("subtitle")}
      </p>
      {loading ? (
        <div style={{ margin: "20px" }}>
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="file-upload">
            <TextField
              onChange={onTextChange}
              id="outlined-basic"
              label={t("quelposte")}
              variant="outlined"
              style={{
                background: "rgb(255 255 255 / 78%)",
                marginBottom: "10px",
              }}
              fullWidth
              required
            />
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              label={t("uploadfile")}
              types={["pdf", "jpg", "png"]}
              required={true}
            />
          </div>
          <Button
            variant="contained"
            disabled={loading}
            disableElevation={true}
            type="submit"
            style={{ margin: "10px" }}
          >
            {t("analyze")}
          </Button>
        </form>
      )}
      {error && <p className="error">{error}</p>}
      <div className="seoblock">
        <h2>{t("seotitle")}</h2>
        <p>
          {t("seobloc1")}
          <br />
          {t("seobloc2")}
          <br />
          {t("seobloc3")}
        </p>
      </div>
    </div>
  );
}

export default App;
