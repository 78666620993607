export const images_ftp = "https://cvchecker-ai.com/data/cvchecker_photos";
export const placeholders = {
  positives: [
    "Sed do eiusmod you incididunt ut labore ostrud exercostrud exerc. Lorem ipsum dolor you amet, consectetur adipiscing elit smod tempor inci. Ut enim ad minim veniam ostrud exercostrud exerc. et dolore you aliqua smod tempor inci.",
    "Et dolore magna aliqua smod tempor inci. Lorem ipsum dolor sit amet, consectetur the elit smod tempor inci. Ut enim ad minim veniam ostrud exercostrud exerc.",
  ],
  negatives: [
    "Ut enim ad minim veniam ostrud exercostrud exerc. et dolore you aliqua smod tempor inci. Lorem ipsum dolor sit amet, consectetur adipiscing elit smod tempor inci. Ut enim ad minim veniam ostrud exercostrud exerc.",
    "quis nostrud exercitation you laboris nisi ostrud exerc llamco laboris. Ut enim ad minim veniam ostrud exercostrud exerc. et dolore you aliqua smod tempor inci. Lorem ipsum dolor sit amet, consectetur adipiscing elit smod tempor inci.",
    "ut aliquip the ea commodo consequat smod tempor inci. Lorem ipsum dolor sit amet, consectetur adipiscing the smod tempor inci. Ut enim ad minim veniam ostrud exercostrud exerc.",
  ],
};
