import React from "react";
import "./styles.css";
import "../LanguageSelect";
import LanguageSelect from "../LanguageSelect";

function NavBar() {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/assets/logo.png"
            alt="CV Checker"
            height={30}
            onClick={() => (window.location.href = "/")}
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
          <a href="/" className="navbar-logo">
            CV Checker
          </a>
        </div>
        <ul className="navbar-menu">
          <li>
            <LanguageSelect />
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
