import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { theme } from "./config/theme";

import Root from "./routes/root";
import Results from "./routes/results";
import Legal from "./routes/legal";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROJECT_ID + ".firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:
    process.env.REACT_APP_FIREBASE_PROJECT_ID + "curricuscan.appspot.com",
  messagingSenderId: "551180076956",
  appId: "1:551180076956:web:7f3dec466153b0f2c46a59",
  measurementId: "G-3E9JPHJ5V9",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Router
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/results",
    element: <Results />,
  },
  {
    path: "/legal",
    element: <Legal />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <div className="App">
        <NavBar />
        <RouterProvider router={router} />
        <Footer />
      </div>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
