import { createTheme } from "@mui/material/styles";

// MUI Theme
export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00796B",
      contrastText: "#FFFFFF",
    },
  },
});
