import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

export default function Legal() {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "left", maxWidth: "500px", margin: "0 auto" }}>
      <h1 style={{ textAlign: "center" }}>{t("Legal")}</h1>
      <h2>CV Checker</h2>
      <p>{t("All rights reserved.")}</p>
      <p>contact@cvchecker-ai.com</p>
      <h2>{t("Author and publisher")}</h2>
      <p>
        <strong>JUVENTIN</strong>
      </p>
      <p>66 Impasse Salvias - 34170 Castelnau-Le-Lez - France</p>
      <p>SIREN : 978 925 667</p>
      <h2>{t("Host")}</h2>
      <p>
        <strong>OVH</strong>
      </p>
      <p>2 rue Kellermann - 59100 Roubaix - France</p>
      <p>SIREN : 424 761 419</p>
    </div>
  );
}
