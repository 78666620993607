import "./styles.css";
import React, { useEffect, useState } from "react";
import frflag from "./flags/fr.svg";
import enflag from "./flags/gb.svg";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function LanguageSelect() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language.split("-")[0]);

  const handleChange = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    document.title = "CV Checker - " + t("title");
    document.querySelector("meta[name=description]").content =
      t("seotitle") + ". " + t("seobloc2");
  }, [lang]);

  return (
    <Select
      value={lang}
      onChange={handleChange}
      displayEmpty
      sx={{
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        height: "40px",
      }}
    >
      <MenuItem value={"fr"}>
        <img src={frflag} width="20" alt="FR" />
      </MenuItem>
      <MenuItem value={"en"}>
        <img src={enflag} width="20" alt="EN" />
      </MenuItem>
    </Select>
  );
}
